<ng-container *transloco="let t">
<div class="relative overflow-hidden overflow-y-auto overflow-x-hidden max-h-screen">
  <button title="Close modal" (click)="onClose()" class="z-10 w-8 h-8 flex items-center justify-center absolute right-0 top-0 mt-1 mr-1 text-blue-500 hover:text-orange-500 duration-200 ease-in-out transition-colors outline-none">
    <fa-icon [icon]="faClose"></fa-icon>
  </button>
  <div class="w-full flex flex-row flex-wrap">
    <div class="w-full md:w-3/5 p-4 md:p-8 bg-gradient-offer">
      <div class="absolute top-0 left-0 w-full h-full z-0 bg-size-140 bg-no-repeat bg-center animate-wave" style="background-image: url('assets/images/stage/wave-blue.png')"></div>
      <header class="mb-4 pb-4 border-b border-gray-550 border-solid relative">
        <h1 class="font-bold text-2xl text-white font-headline">
          <fa-icon class="text-white mr-2 animate-wiggle-more animate-infinite inline-block" [icon]="faBells"></fa-icon>
          {{title}}
        </h1>
      </header>
      <main class="relative">
        <div class="wysiwyg wysiwyg--dialog" [innerHTML]="description"></div>
        <p class="text-white text-sm mt-4">{{claim}}</p>
        <div class="mt-8">
          <button (click)="onClose(sponsors[0])" class="block bg-blue-500 hover:bg-orange-500 text-white text-sm py-2 px-8 rounded-lg shadow mt-auto transition-all transform duration-700 ease-in-out appearance-none">
            {{t('CONTEST.start')}}
          </button>
        </div>
      </main>
    </div>
    <div class="w-full md:w-2/5">
      <aside class="p-4 md:p-8 bg-white h-full flex flex-wrap relative">
        <div class="relative">
          <div class="grid grid-cols-4 gap-8">
            <ng-container *ngFor="let partner of sponsors; trackBy: trackByFn">
              <button class="appearance-none filter grayscale contrast-200 w-full h-auto object-center object-contain hover:filter-none transition-none duration-200 ease-in-out" (click)="onClose(partner)" [title]="t('UI.visit_sponsors_label') + partner.name">
                <img class="block mx-auto w-full h-auto" [src]="partner.logo?.url" [alt]="partner.name">
              </button>
            </ng-container>
          </div>
        </div>
      </aside>
    </div>
  </div>
</div>
</ng-container>
