import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from './auth-service.service';
import { Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { SessionStore } from '../session/state';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  private message: string;
  private subscription: Subscription;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private authService: AuthService,
    private translate: TranslocoService,
    private authStore: SessionStore,
  ) { }

  // Error Handler
  errorHandler(error: any, type?: string, page?: string): void {

    switch (error.status) {
      case 401:
        if (page === 'login') {
          this.message = `${error.status} - ${error.error.message}`;
        } else {
          this.message = `${error.status} - ${error.error.message}`;
          this.authStore.logout();
          this.router.navigateByUrl('/').then();
        }
        break;
      case 403:
        if (page === 'chat') {
          this.message = `${error.status} - ${this.translate.translate('ERROR.banned')}`;
        } else {
          this.message = `${error.status} - ${error.error.message}`;
          this.router.navigateByUrl('/').then();
        }
        break;
      case 400:
        this.message = `${error.status} - ${this.translate.translate('ERROR.400')}`;
        break;
      default:
        this.message = `${error.status} - ${error.error.message}`;
    }

    this.openSnackBar(this.message);

  }

  // Display Snackbar
  openSnackBar(message: string): any {
    this.snackBar.open(message, 'OK', {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

  logout(): any {
    this.subscription = this.authService.logout().subscribe(
      () => {
        this.authStore.logout();
        this.router.navigateByUrl('/').then();
      },
      () => {},
      () => {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
      }
    );
  }
}
