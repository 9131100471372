import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { TranslocoService } from '@ngneat/transloco';
import { Attachment } from '../../interfaces/attachment';
import { Router } from '@angular/router';
import { SlugifyPipe } from '../../pipes/slugify.pipe';
import { faBells } from '@fortawesome/pro-solid-svg-icons';

@Component({
	selector: 'app-dialog-contest',
	templateUrl: './dialog-contest.component.html',
	styleUrls: ['./dialog-contest.component.scss'],
})
export class DialogContestComponent implements OnInit {
	private browserLanguage: string;

	public logoUrl: string;
	public headline: string;
	public button: string;

	public attachment: Attachment;
	public title: string;
	public claim: string;
	public description: string;
	public sponsors: any;
	public conf: any;

	faClose = faTimes;
	faBells = faBells;

	constructor(
		public dialogRef: MatDialogRef<DialogContestComponent>,
		private translate: TranslocoService,
		@Inject(MAT_DIALOG_DATA) data: any,
		public router: Router,
		public sugify: SlugifyPipe
	) {
		this.attachment = data.image;
		this.title = data.title;
		this.description = data.description;
		this.claim = data.claim;
		this.sponsors = data.sponsors;
		this.conf = data.conf;
	}

	ngOnInit(): void {
		this.initLanguage();
		this.logoUrl = 'assets/logo/espresso-tutorials-logo.png';
	}

	onClose(sponsor: any): void {
		if (sponsor) {
			this.goToSponsor(sponsor);
		} else {
			this.dialogRef.close();
		}
	}

	initLanguage(): void {
		this.browserLanguage = this.translate.getActiveLang();
		if (this.browserLanguage === 'de') {
			this.button = 'Jetzt loslegen und teilnehmen';
		} else {
			this.button = 'Start now and participate';
		}
	}

	goToSponsor(sponsor: any): void {
		this.router.navigateByUrl(`/conference/${this.conf}/partner/${sponsor.id}/overview/${this.sugify.transform(sponsor.name)}`).then(() => {
			this.dialogRef.close(true);
		});
	}

	// Performance improvement
	trackByFn(index: any): number {
		return index;
	}
}
