// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	SHARE_URL: 'https://staging.infoday.io',
	COOKIE_SECURE: false,
	COOKIE_DOMAIN: 'infoday.io',
	LinkedInClientId: '78hfudv1z68ye9',
	LinkedInClientSecret: 'KVR516NeKYKrRklG',
	LinkedInRedirectUrlRegister: 'https://staging.infoday.io/register',
	LinkedInRedirectUrlLogin: 'https://staging.infoday.io/login',
	// API_URL: 'https://api.espresso-tutorials.com/',
	API_URL: 'https://staging.api.espresso-tutorials.com/',
	TRACING: 0,
	VERSION: require('../../package.json').version,
	pusher: '01hzppy59nvpxgdcgk1yj9zgfs',
	pusherCluster: 'eu',
	pusherAppId: '01hzppykq0t11c7aszy2mxy4v0',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
