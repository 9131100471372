import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { faBug, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { ApiService } from '../../services/api-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { blockedDomains } from '../../data/blocked-domains';

@Component({
	selector: 'app-newsletter',
	templateUrl: './newsletter.component.html',
	styleUrls: ['./newsletter.component.scss'],
})
export class NewsletterComponent implements OnInit, OnDestroy, OnDestroy {
	public form!: FormGroup;
	public loading: boolean = false;
	public isValidFormSubmitted: null | boolean = null;
	public error: any | undefined;
	public captchaKey: any;
	public success: boolean = false;
	public privacyPolicy: string = '';

	faBug = faBug;
	faSuccess = faThumbsUp;

	private subscriptions: Subscription[] = [];

	constructor(
		private fb: FormBuilder,
		private apiService: ApiService,
		private errorHandler: ErrorHandlerService,
		private translate: TranslocoService
	) {}

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy(): void {
		if (this.subscriptions && this.subscriptions.length > 0) {
			this.subscriptions.forEach((subscription) => {
				subscription.unsubscribe();
			});
		}
	}

	initForm(): void {
		this.form = this.fb.group({
			email: ['', [Validators.required]],
			privacy: [false, [Validators.requiredTrue]],
		});
	}

	submitForm(): void {
		this.error = undefined;
		this.loading = true;
		this.isValidFormSubmitted = false;

		if (!this.form) {
			this.loading = false;
			return;
		}

		if (this.form.valid) {
			// @ts-ignore
			const mail = this.form.get('email').value.split('@');
			const domain = mail[1];

			const isBanned = blockedDomains.includes(domain);

			if (isBanned) {
				this.error = {
					status: 400,
					message: this.translate.translate('NEWSLETTER.error'),
					errors: [this.translate.translate('NEWSLETTER.error')],
				};
				this.loading = false;
				return;
			}

			this.subscriptions.push(
				this.apiService.newsletterRegistration(this.form.value.email, this.translate.getActiveLang()).subscribe({
					next: () => {
						this.isValidFormSubmitted = true;
						this.loading = false;
						this.success = true;
						this.form.reset();
					},
					error: (err: HttpErrorResponse) => {
						this.errorHandler.errorHandler(err, 'Subscribe Newsletter');
						this.error = err.error;
						this.loading = false;
						this.success = false;
					},
				})
			);
		}
	}

	get email(): any {
		return this.form.get('email');
	}

	get privacy(): any {
		return this.form.get('privacy');
	}
}
