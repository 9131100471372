<ng-container *transloco="let t">
  <ng-container *ngIf="form">
    <form [formGroup]="form" (ngSubmit)="submitForm()" novalidate class="w-full flex flex-row flex-wrap relative text-sm">
      <div class="w-full flex-1">
        <div class="w-full">
          <label for="email-news" class="sr-only" >{{t('NEWSLETTER.field_email_label')}}</label>
          <input
            formControlName="email"
            id="email-news"
            name="email"
            type="email"
            autocomplete="email"
            required
            class="w-full rounded-lg border border-gray-500 px-5 py-3 placeholder-gray-650 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            placeholder="{{t('NEWSLETTER.field_email_placeholder')}}"
          />
          <div
            *ngIf="(email.errors && isValidFormSubmitted != null && !isValidFormSubmitted) || (email.errors && isValidFormSubmitted != null && !isValidFormSubmitted)"
            class="mt-1 rounded border border-solid border-red-500 bg-red-400 px-2 py-1 text-xxs leading-none font-semibold text-white"

          >
            {{t('NEWSLETTER.field_error')}}
          </div>
        </div>
        <div class="mt-2 flex w-full flex-wrap items-center">
          <input
            formControlName="privacy"
            id="privacy-news"
            name="privacy"
            type="checkbox"
            class="focus:border-indigo-300 rounded border-gray-500 text-blue-500 shadow-sm outline-none focus:outline-none focus:ring-0 focus:ring-transparent"
          />
          <label for="privacy-news" class="ml-2 block cursor-pointer text-sm text-gray-400">
            <a href="{{t('NEWSLETTER.privacy_link')}}" target="_blank" rel="noopener" class="font-medium underline" >{{t('NEWSLETTER.privacy_label')}}</a>
            <sup class="text-red-500">*</sup>
          </label>
          <ng-container *ngIf="(privacy.errors && isValidFormSubmitted != null && !isValidFormSubmitted) || (privacy.errors && isValidFormSubmitted != null && !isValidFormSubmitted)">
            <div class="w-full mt-1 rounded border border-solid border-red-500 bg-red-400 px-2 py-1 text-xxs leading-none font-semibold text-white" >
              {{t('NEWSLETTER.field_error')}}
            </div>
          </ng-container>
        </div>
      </div>
      <div class="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0 w-full sm:w-auto">
        <button type="submit" class="flex w-full items-center justify-center rounded-lg border-2 border-solid border-blue-500 bg-blue-500 px-3 py-3 text-sm font-semibold text-white shadow-sm transition-colors duration-200 ease-in-out hover:border-orange-500 hover:bg-orange-500 focus:outline-none" [disabled]="loading || isValidFormSubmitted"
        >
          {{t('NEWSLETTER.cta_label')}}
        </button>
      </div>
      <div *ngIf="error" class="mt-4 w-full rounded-lg border border-solid border-red-500 bg-red-400 px-3 py-2 text-xs font-semibold text-white">
        <div class="mb-1 flex flex-row flex-wrap items-center">
          <fa-icon class="mr-1" [icon]="faBug"></fa-icon>
          <span class="font-semibold" >Error:</span>
        </div>
        <p>{{ error.message }}</p>
        <code class="font-normal text-gray-300" *ngIf="error.errors">
          {{ error.errors | json }}
        </code>
      </div>
      <div *ngIf="success" class="border-green-500 rounded-lg border border-solid bg-green-500 p-4 text-xs font-semibold text-white absolute w-full top-0 left-0">
        <div class="flex flex-row flex-wrap items-center" [innerHTML]="t('NEWSLETTER.success')">
        </div>
      </div>
    </form>
  </ng-container>
</ng-container>
