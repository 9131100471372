import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GeoIp } from '../interfaces/geoip';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	public apiUrl = environment.API_URL;

	constructor(private http: HttpClient) {}

	// get data
	getData(path: string): Observable<any> {
		return this.http.get(`${this.apiUrl}${path}`);
	}

	// get detail data
	getItem(path: string, id: number, params?: string): Observable<any> {
		if (params) {
			return this.http.get(`${this.apiUrl}${path}/${id}${params}`);
		} else {
			return this.http.get(`${this.apiUrl}${path}/${id}`);
		}
	}

	// Attend to conference
	attendConference(id: number, privacy: boolean): Observable<any> {
		const body = {
			privacyPolicy: privacy,
		};

		return this.http.post(`${this.apiUrl}conference/conferences/${id}/attend`, body);
	}

	// Check conference attendance
	checkAttendence(id: number): Observable<any> {
		return this.http.get(`${this.apiUrl}conference/conferences/${id}/attendance`, { observe: 'response' });
	}

	// download data
	download(url: string): Observable<Blob> {
		return this.http.get(`${this.apiUrl}${url}`, {
			responseType: 'blob',
		});
	}

	// Attend to favorites
	addFavorites(id: number): Observable<any> {
		const body = {};

		return this.http.post(`${this.apiUrl}conference/sessions/${id}/favor`, body);
	}

	// Remove to favorites
	removeFavorites(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}conference/sessions/${id}/favor`);
	}

	// Add rating
	addRating(id: number, data: any): Observable<any> {
		const body = {
			rating: parseFloat(data.rating),
			ratingCriteria: data.ratingCriteria ? data.ratingCriteria : [],
			description: data.description ? data.description : null,
		};

		return this.http.put(`${this.apiUrl}conference/sessions/${id}/rate`, body);
	}

	// Get rating
	getRating(id: number): Observable<any> {
		return this.http.get(`${this.apiUrl}conference/sessions/${id}/rate`);
	}

	// Add rating
	updateUser(data: any): Observable<any> {
		const body = {
			givenName: data.givenName,
			familyName: data.familyName,
			company: data.company,
			addressLine1: data.addressLine1,
			addressLine2: data.addressLine2,
			postcode: data.postcode,
			city: data.city,
			country: data.country,
			language: data.language,
			timezone: data.timezone,
			position: data.position,
			phone: data.phone,
			email: data.email,
			sapConnectionType: data.sapConnectionType,
		};

		return this.http.put(`${this.apiUrl}accounting/me`, body);
	}

	// Update timezone
	updateTimezone(data: any): Observable<any> {
		const body = {
			timezone: data.timezone,
		};

		return this.http.put(`${this.apiUrl}accounting/me/timezone`, body);
	}

	// get ip for adblocker
	// getIpAddress(): Observable<any> {
	//   return this.http.get<GeoIp>('https://api.ipgeolocation.io/ipgeo?apiKey=1e1ed05ce2474dbdab85c6a0fe8f343a');
	// }

	// Get chat messages from api
	getChatMessages(id: number): Observable<any> {
		return this.http.get<any>(`${this.apiUrl}conference/sessions/${id}/chat/messages`);
	}

	// Load more chat messages from api
	getMoreChatMessages(id: number, lastId: number): Observable<any> {
		return this.http.get<any>(`${this.apiUrl}conference/sessions/${id}/chat/messages?id=${lastId}`);
	}

	// Push chat messages to api
	sendChatMessages(id: number, message: any, sockedId: string): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append('X-Socket-ID', sockedId);
		return this.http.post<any>(`${this.apiUrl}conference/sessions/${id}/chat/messages`, { message }, { headers });
	}

	// Delete chat messages from api
	deleteChatMessage(id: number, message: any): Observable<any> {
		return this.http.delete<any>(`${this.apiUrl}conference/sessions/${id}/chat/messages/${message}`);
	}

	// Submit contest answer
	submitAnswer(answer: string, sponsor: number): Observable<any> {
		const body = {
			answer: answer,
		};

		return this.http.post(`${this.apiUrl}competitions/${sponsor}`, body);
	}

	/**
	 * Newsletter registration
	 * @param email type: string
	 * @param lang type: string
	 */
	newsletterRegistration(email: string, lang: string): Observable<any> {
		const url = `${this.apiUrl}api/newsletter/subscribe?locale=${lang}`;
		const body = {
			email: email,
			tags: ['Newsletter 10%'],
		};

		return this.http.post<any>(url, body);
	}
}
