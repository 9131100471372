<section class="w-full bg-gray-300 px-4 sm:px-8 md:px-12 py-8">
  <div class="bg-blue-200 px-8 md:px-16 py-24 rounded-md w-full max-w-content mx-auto">
    <h2 class="text-2xl md:text-4xl font-medium text-gray-900 leading-tight w-full max-w-imageGrid mx-auto text-center">Ihre Lernplattform für<br>SAP-Software</h2>
    <hr class="w-full max-w-ruler h-1 bg-orange-500 mx-auto my-4 border-0">
    <p class="w-full max-w-imageGrid mx-auto text-center font-light text-gray-700 mt-4">Kostenloser und unverbindlicher Testzugang für unsere digitale Bibliothek noch heute!</p>
    <p class="text-center mt-8">
      <a class="inline-block mx-auto text-center rounded-md bg-blue-500 hover:bg-orange-500 text-white py-2 px-8 text-sm font-medium transition-all transform duration-700 ease-in-out" href="#" target="_blank" rel="noopener">
        7 Tage kostenlos testen
      </a>
    </p>
  </div>
</section>
